import { Link } from "react-router-dom";

const list_menu = [
    { name: 'Visi & Misi', href: `/prodi/profil` },
    { name: 'Akreditasi', href: `/prodi/akreditasi` },
    { name: 'Daftar Dosen', href: `/prodi/dosen` },
    { name: 'Kurikulum', href: `/prodi/kurikulum` },
    { name: 'Panduan KTI', href: `/prodi/panduan_kti` },
]

export default function LinkTerkait({ ...props }) {
    const nama_prodi = props.publisher.nama;
    const kode_prodi = props.publisher.kode;
    return (
        <>
            <h3 className="text-xl font-bold leading-6 text-gray-900 mb-5">Link Terkait</h3>
            <div className="border-t border-gray-200">
                {list_menu.map((item, i) => (
                    <Link key={i} to={`${item.href}`} state={{ kode: kode_prodi, nama: nama_prodi }} className="block py-2 border-b hover:text-blue-900">{item.name}</Link>
                ))}
            </div>
        </>
    )
}