import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

const list_menu = [
    { name: 'LPM', href: 'https://lpm.stikesmucis.ac.id/', target:'_lpm' },
    { name: 'LPPM', href: 'https://lppm.stikesmucis.ac.id/', target:'_lppm' },
]

export default function MenuLembaga() {
    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center items-center focus:outline-none text-gray-700 hover:text-blue-700 px-1 py-1">
                        Lembaga
                        <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {list_menu.map((item, i) => (
                                <a key={i} href={item.href} target={item.target} rel="noopenner noreferrer" className="text-gray-700 block px-4 py-2 text-sm hover:bg-blue-100 hover:text-blue-700">{item.name}</a >
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}