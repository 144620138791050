import LayoutContent from "../../Partials/LayoutContent";

const menuOM = [
    { name: 'IMM (Ikatan Mahasiswa Muhammadiyah)' },
    { name: 'DPM (Dewan Perwakilan Mahasiswa)' },
    { name: 'BEM (Badan Eksekutif Mahasiswa)' },
    { name: 'SEMA (Senat Mahasiswa)' },
]

const menuUKM = [
    { name: 'UKM Paduan Suara' },
    { name: 'UKM Tari' },
    { name: 'UKM Pencak Silat' },
    { name: 'UKM Futsal' },
    { name: 'UKM Volly' },
    { name: 'UKM Badminton' },
]

const isiKonten =
    <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900">Organisasi Mahasiswa</div>
                    </div>
                    <div className="border-t border-gray-200">
                        {menuOM.map((list, i) => (
                            <div key={i} className="bg-white px-6 py-3 border-b">
                                <dt className="sm:mb-0 mb-3">{list.name}</dt>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900">Unit Kegiatan Mahasiswa</div>
                    </div>
                    <div className="border-t border-gray-200">
                        {menuUKM.map((list, i) => (
                            <div key={i} className="bg-white px-6 py-3 border-b">
                                <dt className="sm:mb-0 mb-3">{list.name}</dt>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>

export default function Kemahasiswaan() {
    return <LayoutContent title="Kemahasiswaan" description={null} konten={isiKonten} />
}