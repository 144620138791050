import { Link } from "react-router-dom";

const list_menu = [
    { name: 'Visi & Misi', href: `/profil/visimisi` },
    { name: 'Akreditasi', href: `/profil/akreditasi` },
    { name: 'Fasilitas', href: `/profil/fasilitas` },
    { name: 'Dosen dan Tenaga Kependidikan', href: `/profil/dosentendik` },
    { name: 'Sejarah', href: `/profil/sejarah` },
    { name: 'Makna Logo', href: `/profil/makna_logo` },
    { name: 'Lagu-lagu', href: `/profil/lagu` },
    { name: 'Kalender Akademik', href: `/kalender` },
]

export default function LinkTerkait() {
    return (
        <>
            <h3 className="text-xl font-bold leading-6 text-gray-900 mb-5">Link Terkait</h3>
            <div className="border-t border-gray-200">
                {list_menu.map((item, i) => (
                    <Link key={i} to={item.href} className="block py-2 border-b hover:text-blue-900">{item.name}</Link>
                ))}
            </div>
        </>
    )
}