import LayoutContent from "../../Partials/LayoutContent";
import GetBerita from "./GetBerita";
import GetInformasi from "./GetInformasi";
import GetSlide from "./GetSlide";
import LogoIBI from "./LogoProfesi/logo-ibi.png"
import LogoPPNI from "./LogoProfesi/logo-ppni.png"
import LogoPAFI from "./LogoProfesi/logo-pafi.png"
import LogoPATELKI from "./LogoProfesi/logo-patelki.png"
import GetVisiMisi from "./GetVisiMisi";
import GetSertifikatAkreditasiKampus from "./GetSertifikatAkreditasiKampus";
import GetFasilitas from "./GetFasilitas";
import { Link } from "react-router-dom";

const isiKonten = <>
    <header className="bg-gray-100">
        <GetSlide />
    </header>

    <GetVisiMisi />

    <div className="bg-gray-50 w-full py-8 lg:py-12 border-b-2 border-gray-50 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-8 lg:gap-12">
            <GetBerita limit="10" />
            <GetInformasi limit="10" />
        </div>
    </div>

    <GetSertifikatAkreditasiKampus />

    <div className='bg-white'>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12 border-b-2 border-gray-50">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center">
                <div className="text-center">
                    <div className="mb-4 text-xl md:text-3xl font-bold">Program Studi</div>
                    <div className="text-gray-500 mb-5 md:mb-10 text-sm md:text-lg">Program Studi sudah terakreditasi LAM-PTKes dengan predikat B</div>
                    <Link to="/prodi" className="py-2 px-4 bg-blue-700 hover:bg-blue-800 text-white rounded-lg text-sm md:text-lg">Lihat Selengkapnya &raquo;</Link>
                </div>
                <iframe className="w-full h-52 sm:h-80 bg-gray-100 rounded-xl" src="https://www.youtube.com/embed/XhCvBcIDocU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    </div >

    <GetFasilitas limit="10" />

    <div className='bg-gray-50'>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12">
            <div className="text-center mb-10">
                <p className="text-xl font-bold text-gray-900 md:text-3xl mb-4">Organisasi Profesi</p>
                <p className="mx-auto max-w-2xl text-sm md:text-lg text-gray-500">
                    Organisasi profesi berdasarkan bidang keilmuan setiap Program Studi
                </p>
            </div>
            <div className="flex justify-between">
                <a href="https://ibi.or.id/" target="_ibi" rel="noopenner noreferrer" className="flex justify-center">
                    <img src={LogoIBI} className="md:w-40 sm:w-28 w-20" alt="Logo IBI" loading="lazy" />
                </a>
                <a href="https://ppni-inna.org/" target="_ibi" rel="noopenner noreferrer" className="flex justify-center">
                    <img src={LogoPPNI} className="md:w-40 sm:w-28 w-20" alt="Logo PPNI" loading="lazy" />
                </a>
                <a href="https://pafi.or.id/" target="_ibi" rel="noopenner noreferrer" className="flex justify-center">
                    <img src={LogoPAFI} className="md:w-40 sm:w-28 w-20" alt="Logo PAFI" loading="lazy" />
                </a>
                <a href="https://patelki.or.id/" target="_ibi" rel="noopenner noreferrer" className="flex justify-center">
                    <img src={LogoPATELKI} className="md:w-40 sm:w-28 w-20" alt="Logo PATELKI" loading="lazy" />
                </a>
            </div>
        </div>
    </div >
</>

export default function Home() {
    return <LayoutContent title="Website" description="Website Resmi STIKes Muhammadiyah Ciamis - STIKes Mucis" konten={isiKonten} />
}
