import MyIcon from './logo-stikes-muhammadiyah-ciamis.png';
import { ClockIcon, EnvelopeIcon, MapIcon, PhoneIcon } from '@heroicons/react/24/solid'

export default function Footer() {
    return (
        <>
            <div className='bg-blue-900 text-white'>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12">
                    <div className="md:flex grid gap-10 md:gap-20 font-light">
                        <div className="md:flex grid items-center gap-10 md:gap-20 grow">
                            <img src={MyIcon} alt={`Logo ${process.env.REACT_APP_KAMPUS}`} className="w-32" loading="lazy" />
                            <div>
                                <p className="font-normal text-md md:text-xl mb-3">Kontak</p>
                                <div className="space-y-2">
                                    <span className='flex items-center gap-2 text-sm md:text-base'>
                                        <PhoneIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                        (0265) 773052
                                    </span>
                                    <span className='flex items-center gap-2 text-sm md:text-base'>
                                        <EnvelopeIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                        humas@stikesmucis.ac.id
                                    </span>
                                    <span className='flex items-center gap-2 text-sm md:text-base'>
                                        <ClockIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                        Senin - Sabtu (08.00 WIB - 14.00 WIB)
                                    </span>
                                    <span className='flex items-center gap-2 text-sm md:text-base'>
                                        <MapIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                        Jln. KH. Ahmad Dahlan No. 20 Ciamis 46216
                                    </span>
                                    <a href="https://bit.ly/2kJ6VlF" target="_maps" rel="noopenner noreferrer" className="flex text-blue-300 text-sm md:text-base">Lihat di Maps</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="font-normal text-md md:text-xl mb-3">Link Terkait</p>
                            <ul className=" space-y-2">
                                <li><a href="https://pddikti.kemdikbud.go.id/" target="_pdpt" rel="noopenner noreferrer" className="text-blue-300 text-sm md:text-base">Pangkalan Data DIKTI</a></li>
                                <li><a href="https://ijazah.kemdikbud.go.id/" target="_ijazah" rel="noopenner noreferrer" className="text-blue-300 text-sm md:text-base">Verifikasi Ijazah (SIVIL)</a></li>
                                <li><a href="https://feeder.stikesmucis.ac.id/" target="_neo" rel="noopenner noreferrer" className="text-blue-300 text-sm md:text-base">Neo Feeder 2022</a></li>
                                <li><a href="http://sister.stikesmucis.ac.id/" target="_sister" rel="noopenner noreferrer" className="text-blue-300 text-sm md:text-base">SISTER</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gray-900 text-gray-300'>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
                    <div className="flex flex-col sm:flex-row sm:justify-between gap-2 text-sm md:text-base font-light">
                        <p className="text-center sm:text-left">
                            &copy; {process.env.REACT_APP_KAMPUS} - 2023
                        </p>
                        <p className="text-center sm:text-right">Dikembangkan oleh Tim IT Mucis</p>
                    </div>
                </div>
            </div >
        </>
    )
}