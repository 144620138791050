import Footer from "./Footer";
import MainContent from "./MainContent";
import Navbar from "./Navbar";
import CekKoneksi from "./CekKoneksi";
import TagHead from "./TagHead";


export default function LayoutContent({ ...props }) {
    TagHead(props.title, props.description);
    return (
        <>
            <CekKoneksi />
            <div className="min-h-full">
                <Navbar />
                <MainContent konten={props.konten} />
                <Footer />
            </div>
        </>
    )
}
