import iconWhatsApp from './social-icons/whatsapp-svg.svg'

export default function MainContent({ ...props }) {
    return (
        <>
            <main className="bg-gray-100">
                {props.konten}

                {/* Floating action button */}
                <a href={`https://wa.me/6282216163200`} target="_wa" rel="noopenner noreferrer" className="fixed z-90 bottom-10 right-8 bg-green-600 w-auto px-4 py-2 rounded-full drop-shadow-lg flex justify-center items-center text-white text-sm hover:bg-green-700 hover:drop-shadow-2xl animate-bounce hover:animate-none duration-500 gap-3"><img src={iconWhatsApp} className="w-4 md:w-6" alt="Chat" /> Hubungi Kami</a>
            </main>
        </>
    )
}