import { AcademicCapIcon, BoltIcon, BookOpenIcon, DevicePhoneMobileIcon, GlobeAltIcon, RocketLaunchIcon } from '@heroicons/react/24/solid'

const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const features = [
    {
        name: 'Pendaftaran',
        description: 'Pendaftaran Mahasiswa Baru',
        icon: RocketLaunchIcon,
        style: 'h-6 w-6 md:h-8 md:w-8 text-blue-900',
        url: 'https://pmb.stikesmucis.ac.id/'
    },
    {
        name: 'Akademik',
        description: 'Sistem Informasi Manajemen Akademik',
        icon: GlobeAltIcon,
        style: 'h-6 w-6 md:h-8 md:w-8 text-yellow-500',
        url: 'https://simak.stikesmucis.ac.id/'
    },
    {
        name: 'Ujian Online',
        description: 'Ujian berbasis komputer',
        icon: DevicePhoneMobileIcon,
        style: 'h-6 w-6 md:h-8 md:w-8 text-red-500',
        url: 'https://cbt.stikesmucis.ac.id/'
    },
    {
        name: 'E-Learning',
        description: 'Pembelajaran Secara Online',
        icon: BoltIcon,
        style: 'h-6 w-6 md:h-8 md:w-8 text-indigo-500',
        url: 'https://lms.stikesmucis.ac.id/'
    },
    {
        name: 'Perpustakaan',
        description: 'Koleksi bahan pustaka',
        icon: BookOpenIcon,
        style: 'h-6 w-6 md:h-8 md:w-8 text-yellow-700',
        url: 'https://lib.stikesmucis.ac.id/'
    },
    {
        name: 'Tracer Study',
        description: 'Kuisioner Pelacakan Lulusan',
        icon: AcademicCapIcon,
        style: 'h-6 w-6 md:h-8 md:w-8 text-gray-800',
        // url: 'https://tracerstudy.stikesmucis.ac.id/'
        url: 'https://tracerstudy.kemdikbud.go.id/kuesioner'
    },
]


export default function AppLink() {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-3 gap-3 lg:gap-3 md:grid-cols-6 place-content-center flex-wrap">
                {features.map((feature, i) => (
                    <div key={i} onClick={() => openInNewTab(feature.url)} className="flex flex-col gap-2 items-center cursor-pointer hover:bg-gray-100 bg-white  text-gray-900 p-3 rounded-xl shadow-sm border border-gray-100">
                        <div className="flex items-center justify-center sm:shrink-0 mb-1 lg:mb-0">
                            <feature.icon className="h-6 w-6 md:h-8 md:w-8 text-blue-700" aria-hidden="true" />
                        </div>
                        <div className="text-center">
                            <p className="font-semibold text-xs sm:text-sm mb-0">{feature.name}</p>
                            <p className=" text-gray-50 text-sm hidden">{feature.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}