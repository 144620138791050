import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetFasilitas() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}fasilitas`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let fasilitas;
    if (error) {
        fasilitas = `<div class="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">${error.message} @Fasilitas</div>`;
    } else if (!isLoaded) {
        fasilitas = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96 w-full">&nbsp;</div>
                </div>`
    } else {
        fasilitas = `<div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">`
        datas.map((list, i) => (
            fasilitas += `<div key=${i} class="group relative">
                <div class="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200">
                    <img
                        src="${list.src_gambar}"
                        alt="${list.nama_fasilitas}"
                        class="h-full w-full object-cover object-center"
                    />
                    <h3 class="text-sm py-2 text-gray-700 text-center">
                        <a href="${list.src_gambar}" target="_${i}" rel="noopenner noreferrer">
                            ${list.nama_fasilitas}
                        </a>
                    </h3>
                </div>
            </div>`
        ))
        fasilitas += `</div>`
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Fasilitas</div>
                        <div className="text-gray-500">Foto-foto beberapa sarana dan prasarana penunjang kegiatan belajar mengajar.</div>
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200">
                        {Parser().parse(fasilitas)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait />
                </div>
            </div>
        </div>

    return <LayoutContent title="Fasilitas Kampus" description={null} konten={isiKonten} />
}