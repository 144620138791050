import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function GetFasilitas({ ...props }) {
    const limit = props.limit;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}fasilitas`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const first10 = result.data.slice(7, limit);
                        setDatas(first10);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [limit])

    let fasilitas;
    if (error) {
        fasilitas = `<div class="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">${error.message} @Fasilitas</div>`
    } else if (!isLoaded) {
        fasilitas = `<div class="group animate-pulse">
                <div class="bg-slate-300 rounded-xl h-96 w-full">&nbsp;</div>
            </div>`
    } else {
        fasilitas = ''
        datas.map((list, i) => (
            fasilitas += `<img key="${i}"
            class="object-cover object-center rounded-xl shadow-lg"
            src="${list.src_gambar}"
            alt="${list.nama_fasilitas}"
            loading="lazy"
        />`
        ))
    }

    return (
        <div className='bg-white'>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12">
                <div className="grid gap-8 md:gap-12 items-center">
                    <div className="text-center">
                        <div className="text-gray-900 text-xl md:text-3xl font-bold mb-4">Fasilitas Kampus</div>
                        <div className="text-gray-500 mb-5 md:mb-10 text-sm md:text-lg">Sarana dan prasarana penunjang pembelajaran</div>
                        <Link to="/profil/fasilitas" className="py-2 px-4 bg-blue-700 hover:bg-blue-800 text-white text-sm md:text-lg rounded-lg">Lihat Selengkapnya &raquo;</Link>
                    </div>
                    <div className="grid grid-1 md:grid-cols-3 gap-5">
                        {Parser().parse(fasilitas)}
                    </div>
                </div>
            </div>
        </div >
    )
}