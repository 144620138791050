import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LayoutContent from "../../Partials/LayoutContent";

export default function GetListProdi() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}publisher`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const newData = result.data.filter(object => {
                            return object.kode_publisher !== '043277';
                        });
                        setDatas(newData);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let prodi;
    if (error) {
        prodi = <div className="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">{error.message} @ProgramStudi</div>
    } else if (!isLoaded) {
        prodi = <div className="group animate-pulse">
            <div className="bg-slate-300 rounded-xl h-96">&nbsp;</div>
        </div>
    } else {
        prodi = <div className="grid md:grid-cols-2 gap-5">
            {datas.map((item, i) => (
                <Link key={i} to="/prodi/profil" state={{ kode: item.kode_publisher, nama: item.nama_publisher }} className="bg-blue-50 border border-blue-100 p-4 rounded-xl text-3xl hover:text-blue-900 hover:bg-blue-100">{item.nama_publisher}</Link>
            ))}
        </div>
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                <div className="px-4 py-5 sm:px-6">
                    <div className="text-3xl font-bold text-gray-900">Program Studi</div>
                </div>
                <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200 grid gap-5">
                    {prodi}
                </div>
            </div>

        </div>

    return <LayoutContent title="Daftar Prodi" description={null} konten={isiKonten} />
}