import { Parser } from "html-to-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetDosen() {
    const location = useLocation()
    const nama_prodi = location.state.nama;
    const kode_prodi = location.state.kode;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        postData(kode_prodi) // Fetch when component is mounted
    }, [kode_prodi])

    const postData = (kode_prodi) => {
        fetch(`${process.env.REACT_APP_URL_API_SIAK}dosen_prodi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                kode_prodi: kode_prodi,
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.result);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            .catch((err) => console.log(err))
    }

    let dosen;
    if (error) {
        dosen = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @Dosen</div>`
    } else if (!isLoaded) {
        dosen = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        let listDosen = '';
        datas.map((list, i) => (
            listDosen += `<div key=${i} class="bg-white py-3 border p-4 sm:rounded-lg">
                <p class="sm:mb-0 mb-3 font-bold">${list.dosen}</p>
                <p class="sm:mb-0 mb-3 text-gray-500">NIDN : ${list.nidn}</p>
            </div>`
        ))

        dosen = `<div class="grid grid-cols-1 gap-4 md:grid-cols-2">${listDosen}</div>`
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Daftar Dosen</div>
                        <div className="text-gray-500">Program Studi {nama_prodi}</div>
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200">
                        {Parser().parse(dosen)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait publisher={{ kode: kode_prodi, nama: nama_prodi }} />
                </div>
            </div>
        </div>

    return <LayoutContent title={`Dosen Prodi ${nama_prodi}`} description={null} konten={isiKonten} />

}