import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetVisiMisi() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [detail, setDetail] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}publisher/043277`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDetail(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let visimisi, profil;
    if (error) {
        profil = `<div class="bg-slate-300 rounded-xl h-14 text-gray-500 flex items-center justify-center">${error.message} @Profil</div>`
        visimisi = `<div class="bg-slate-300 rounded-xl h-60 mb-10 text-gray-500 flex items-center justify-center">${error.message} @Visi</div>`
    } else if (!isLoaded) {
        profil = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-14">&nbsp;</div>
                </div>`
        visimisi = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-60 mb-10">&nbsp;</div>
                </div>`
    } else {
        profil = `<div class="text-3xl font-bold text-gray-900">Profil ${detail.nama_publisher}</div>`

        // visi - misi
        visimisi = `<div className="mb-3 text-xl font-bold text-gray-900">Visi</div>${detail.visi}
        <div className="mb-3 text-xl font-bold text-gray-900 mt-10">Misi</div>${detail.misi}`
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        {Parser().parse(profil)}
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200">
                        {Parser().parse(visimisi)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait />
                </div>
            </div>
        </div>

    return <LayoutContent title="Visi Misi" description={null} konten={isiKonten} />
}