import { Route, Routes } from 'react-router-dom';
import Berita from './components/Menu/Berita/Berita';
import Fasilitas from './components/Menu/Profil/Fasilitas';
import Home from './components/Menu/Home/Home';
import Informasi from './components/Menu/Informasi/Informasi';
import Kalender from './components/Menu/Kalender/Kalender';
import Prodi from './components/Menu/Prodi/Prodi';
import DosenTendik from './components/Menu/Profil/DosenTendik';
import Lagu from './components/Menu/Profil/Lagu';
import Page404 from './components/Partials/Page404';
import Sejarah from './components/Menu/Profil/Sejarah';
import Kemahasiswaan from './components/Menu/Kemahasiswaan/Kemahasiswaan';
import MaknaLogo from './components/Menu/Profil/MaknaLogo';
import Dosen from './components/Menu/Prodi/Dosen';
import Kurikulum from './components/Menu/Prodi/Kurikulum';
import PanduanKti from './components/Menu/Prodi/PanduanKti';
import ListProdi from './components/Menu/Prodi/ListProdi';
import Akreditasi from './components/Menu/Profil/Akreditasi';
import VisiMisi from './components/Menu/Profil/VisiMisi';
import AkreditasiProdi from './components/Menu/Prodi/AkreditasiProdi';
import Pedoman from './components/Menu/Pedoman/Pedoman';

function App() {
  return (
    <div className="h-screen">
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/kalender' element={<Kalender />}></Route>
        <Route path='/berita/:slug' element={<Berita />}></Route>
        <Route path='/informasi/:slug' element={<Informasi />}></Route>
        <Route path='/profil' element={<VisiMisi />}></Route>
        <Route path='/profil/visimisi' element={<VisiMisi />}></Route>
        <Route path='/profil/sejarah' element={<Sejarah />}></Route>
        <Route path='/profil/fasilitas' element={<Fasilitas />}></Route>
        <Route path='/profil/makna_logo' element={<MaknaLogo />}></Route>
        <Route path='/profil/lagu' element={<Lagu />}></Route>
        <Route path='/profil/dosentendik' element={<DosenTendik />}></Route>
        <Route path='/profil/akreditasi' element={<Akreditasi />}></Route>
        <Route path='/prodi' element={<ListProdi />}></Route>
        <Route path='/prodi/profil' element={<Prodi />}></Route>
        <Route path='/prodi/akreditasi' element={<AkreditasiProdi />}></Route>
        <Route path='/prodi/dosen' element={<Dosen />}></Route>
        <Route path='/prodi/kurikulum' element={<Kurikulum />}></Route>
        <Route path='/prodi/panduan_kti' element={<PanduanKti />}></Route>
        <Route path='/pedoman' element={<Pedoman />}></Route>
        <Route path='/kemahasiswaan' element={<Kemahasiswaan />}></Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  )
}

export default App;
