import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function GetSertifikatAkreditasiKampus() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [detail, setDetail] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}akreditasi/043277`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDetail(result.data.slice(0, 1));
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let akreditasi, img;
    if (error) {
        akreditasi = `<div class="bg-slate-300 rounded-xl text-gray-500 flex items-center justify-center mb-10">${error.message} @PredikatAkreditasi</div>`
        img = `<div class="bg-slate-300 rounded-xl h-96 w-96 text-gray-500 flex items-center justify-center">${error.message} @SertifikatAkreditasi</div>`
    } else if (!isLoaded) {
        akreditasi = `<div class="group animate-pulse mb-10">
                <div class="bg-slate-300 rounded-xl h-10">&nbsp;</div>
            </div>`
        img = `<div class="group animate-pulse">
                <div class="bg-slate-300 rounded-xl h-96 w-96">&nbsp;</div>
            </div>`
    } else {
        // sertifikat akreditasi
        if (detail[0].file_akreditasi) {
            img = `<img src=${detail[0].file_akreditasi} alt="Sertifikat Akreditasi Kampus" class="h-auto w-screen shadow-md rounded-xl object-cover object-center hover:scale-110" loading="lazy" />`
        } else {
            img = `<p class="flex h-96 w-96 items-center justify-center bg-gray-700 text-white px-4 text-sm font-medium text-dark sm:px-6 lg:px-8 rounded-xl">
                Sertifikat akreditasi belum diunggah
            </p>`
        }

        // predikat akreditasi
        const predikat = (detail[0].predikat) ? `Terakreditasi ${detail[0].lembaga_akreditasi} dengan predikat ${detail[0].predikat}` : '';
        akreditasi = `<div class="text-xl font-bold text-white md:text-3xl mb-5 md:mb-10">${predikat}</div>`
    }

    return (
        <div className='bg-gray-900'>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12">
                <div className="flex md:justify-between flex-col-reverse md:flex-row gap-8 md:gap-12 items-center">
                    <div>{Parser().parse(img)}</div>
                    <div className="text-center w-screen">
                        <div className="text-white mb-5 text-sm md:text-lg">Akreditasi Institusi</div>
                        {Parser().parse(akreditasi)}
                        <Link to="/profil/akreditasi" className="py-2 px-4 bg-blue-700 hover:bg-blue-800 text-white text-sm md:text-lg rounded-lg">Lihat Selengkapnya &raquo;</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}