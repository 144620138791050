import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetLagu() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}lagu`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let lagu = '';
    if (error) {
        lagu = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @Lagu</div>`
    } else if (!isLoaded) {
        lagu = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        datas.map((list, i) => (
            lagu += `<div key=${i} class="py-3 grid grid-cols-1 sm:grid-cols-3 gap-4 items-center border-b">
                <div class="col-span-2">${list.nama_lagu}</div>
                <div class="sm:text-right py-2">
                    <a href=${list.link_download} target=${i} rel="noopenner noreferrer" class="bg-blue-200 hover:bg-blue-300 text-blue-800 py-2 px-3 rounded-lg text-xs">Lihat &raquo;</a>
                </div>
            </div>`
        ))
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Lagu-lagu</div>
                        <div className="text-gray-500">Lagu-lagu yang biasa digunakan di lingkungan kampus</div>
                    </div>
                    <div className="text-justify p-6 border-t border-gray-200">
                        {Parser().parse(lagu)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait />
                </div>
            </div>
        </div>

    return <LayoutContent title="Lagu-lagu" description={null} konten={isiKonten} />
}