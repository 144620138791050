import React from 'react'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChatBubbleOvalLeftIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid'
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import MyLogo from './logo-nav-stikes-muhammadiyah-ciamis.png';
import MyIcon from './logo-stikes-muhammadiyah-ciamis.png';
import MenuProdi from '../Menu/Dropdown/MenuProdi';
import MenuProfil from '../Menu/Dropdown/MenuProfil';
import MenuLembaga from '../Menu/Dropdown/MenuLembaga';
import MenuExternal from '../Menu/Dropdown/MenuExternal';
import iconWhatsApp from './social-icons/whatsapp-svg.svg'
import iconYoutube from './social-icons/youtube-svg.svg'
import iconFacebook from './social-icons/facebook-svg.svg'
import iconInstagram from './social-icons/instagram-svg.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MenuLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <Link to={to} {...props} className={classNames(isActive ? ' text-blue-700' : 'hover:text-blue-700 text-gray-700', 'block px-1 py-1 ')}>
            {children}
        </Link >
    )
}

export default function Navbar() {
    return (
        <div className=' sticky top-0 z-10 shadow-lg'>
            <div className="bg-blue-900">
                <div className="mx-auto max-w-7xl py-2 px-3 sm:px-6 lg:px-8">
                    <div className="flex flex-wrap items-center justify-end md:justify-between">
                        <div className="hidden md:flex w-0 flex-1 items-center text-white text-sm font-light space-x-6">
                            <span className='flex items-center gap-2'>
                                <PhoneIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                (0265) 773052
                            </span>
                            <span className='flex items-center gap-2'>
                                <EnvelopeIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                humas@stikesmucis.ac.id
                            </span>
                            <span className='flex items-center gap-2'>
                                <ChatBubbleOvalLeftIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                <a href={`https://wa.me/6282216163200`} target="_wa" rel="noopenner noreferrer">0822 1616 3200</a>
                            </span>
                        </div>
                        <div className="flex items-center gap-6 text-white">
                            <a href={`https://wa.me/6282216163200`} target="_wa" rel="noopenner noreferrer"><img src={iconWhatsApp} className="h-4 w-4" alt="" /></a>
                            <a href="https://www.instagram.com/stikesmucis/" target="_ig" rel="noopenner noreferrer"><img src={iconInstagram} className="h-4 w-4" alt="" /></a>
                            <a href="https://id-id.facebook.com/stikesmucis/" target="_ig" rel="noopenner noreferrer"><img src={iconFacebook} className="h-4 w-4" alt="" /></a>
                            <a href="https://www.youtube.com/@STIKESMUCISKERENTV/featured" target="_ig" rel="noopenner noreferrer"><img src={iconYoutube} className="h-4 w-4" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <Disclosure as="nav" className="bg-white">
                {({ open_top }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 sm:py-4">
                            <div className="flex items-center justify-between text-sm xl:text-base">
                                <div className="flex-shrink-0 flex justify-start items-center">
                                    <Link to={'/'}>
                                        <img src={MyLogo} alt={`Logo ${process.env.REACT_APP_KAMPUS}`} className="h-10 w-auto sm:w-auto sm:h-14 mr-4 block lg:hidden xl:block" />
                                        <img src={MyIcon} alt={`Logo ${process.env.REACT_APP_KAMPUS}`} className="h-10 w-auto sm:w-auto sm:h-14 mr-4 hidden lg:block xl:hidden" />
                                    </Link>
                                </div>
                                <div className="flex items-center">
                                    <div className="hidden lg:block mr-0 lg:mr-4">
                                        <div className='grid grid-flow-col gap-4'>
                                            <MenuLink to={'/'}>Home</MenuLink>
                                            <MenuProfil to={'/profil'} class="text-blue-700" />
                                            <MenuProdi to={'/prodi'} class="text-blue-700" />
                                            <MenuLink to={'/kemahasiswaan'}>Kemahasiswaan</MenuLink>
                                            <MenuLink to={'/pedoman'}>Pedoman</MenuLink>
                                            <MenuLembaga />
                                            <MenuExternal />
                                        </div>
                                    </div>
                                    <a
                                        href="https://pmb.stikesmucis.ac.id/buat_akun"
                                        target='_pmb'
                                        rel='noopenner noreferrer'
                                        className="flex items-center justify-center rounded-md border border-transparent bg-blue-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-900"
                                    >
                                        Daftar PMB
                                    </a>
                                    <div className="flex lg:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="bg-transparent inline-flex items-center justify-center rounded-md text-blue-800 hover:text-blue-900 focus:outline-none focus:bg-gray-300 ml-3">
                                            <span className="sr-only">Open main menu</span>
                                            {open_top ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="lg:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                <MenuLink to={'/'}>Home</MenuLink>
                                <MenuProfil to={'/profil'} class="text-blue-700" />
                                <MenuProdi to={'/prodi'} class="text-blue-700" />
                                <MenuLink to={'/kemahasiswaan'}>Kemahasiswaan</MenuLink>
                                <MenuLink to={'/pedoman'}>Pedoman</MenuLink>
                                <MenuLembaga />
                                <MenuExternal />
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
}