import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetDosenTendik() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setData] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API_SIMPEG}list_pegawai?jenis=all`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setData(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let dosentendik;
    if (error) {
        dosentendik = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @Dosen_Tendik</div>`
    } else if (!isLoaded) {
        dosentendik = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        let listDosenTendik = '';
        datas.map((list, i) => (
            listDosenTendik += `<div key=${i} class="bg-white py-3 border p-4 sm:rounded-lg">
                <p class="sm:mb-0 mb-3 font-bold">${list.nm_pegawai}</p>
                <p class="sm:mb-0 mb-3 text-gray-500">${list.jenis_pegawai}</p>
                <p class="sm:mb-0 mb-3 text-gray-500">NIP : ${list.nip_pegawai}</p>
            </div>`
        ))

        dosentendik = `<div class="grid grid-cols-1 gap-4 md:grid-cols-2">${listDosenTendik}</div>`
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Dosen dan Tenaga Kependidikan</div>
                        <div className="text-gray-500">Daftar Nama Dosen (Tenaga Pendidik) dan Tenaga Kependidikan</div>
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200">
                        {Parser().parse(dosentendik)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait />
                </div>
            </div>
        </div>

    return <LayoutContent title="Dosen dan Tenaga Kependidikan" description={null} konten={isiKonten} />
}