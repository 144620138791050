import { useEffect } from 'react'

export default function TagHead(title, description) {
    const judul = `${title} | ${process.env.REACT_APP_KAMPUS}`;
    const deskripsi = (description) ? description : judul;
    document.querySelector('meta[name="description"]').setAttribute("content", deskripsi);
    document.querySelector('link[rel="canonical"]').setAttribute("href", window.location.href);
    useEffect(() => {
        document.title = judul;
    }, [judul]);

}
