import { useState, useEffect } from "react";
import AppLink from "./AppLink";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/outline";

export default function GetSlide() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);
    let [current, setCurrent] = useState(0);

    let previousSlide = () => {
        if (current === 0) setCurrent(datas.length - 1);
        else setCurrent(current - 1);
    };

    let nextSlide = () => {
        if (current === datas.length - 1) setCurrent(0);
        else setCurrent(current + 1);
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}slide/${process.env.REACT_APP_KODE}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return (
            <>
                <div className="bg-white w-full py-4 lg:py-7">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-5">
                        <div className="bg-slate-300 rounded-xl h-72 text-gray-500 flex items-center justify-center">{error.message} @Slide</div>
                        <div>
                            <AppLink />
                        </div>
                    </div>
                </div>
            </>
        )
    } else if (!isLoaded) {
        return (
            <>
                <div className="bg-white w-full py-4 lg:py-7">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-5">
                        <div className="group animate-pulse">
                            <div className="bg-slate-300 rounded-xl h-72">&nbsp;</div>
                        </div>
                        <div>
                            <AppLink />
                        </div>
                    </div>
                </div>
            </>
        )
    } else {

        return (
            <div className="bg-white w-full py-6 border-b-2 border-gray-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-5">
                    <div className="overflow-hidden relative">
                        <div
                            className={`flex transition ease-out duration-40`}
                            style={{
                                transform: `translateX(-${current * 100}%)`,
                            }}
                        >
                            {datas.map((list, i) => {
                                return <img key={i} src={list.src_gambar} className="block w-full h-max rounded-xl" alt={list.judul_gambar} loading="lazy" />;
                            })}
                        </div>

                        <div className="absolute top-0 h-full w-full justify-between items-center flex text-white text-opacity-50 px-10 text-xl">
                            <button onClick={previousSlide}><ArrowLeftCircleIcon /> Prev</button>
                            <button onClick={nextSlide}><ArrowRightCircleIcon /> Next</button>
                        </div>

                        <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
                            {datas.map((s, i) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setCurrent(i);
                                        }}
                                        key={"circle" + i}
                                        className={`rounded-full w-3 h-3 cursor-pointer  ${i === current ? "bg-white" : "bg-gray-500 bg-opacity-40"
                                            }`}
                                    ></div>
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <AppLink />
                    </div>
                </div>
            </div>
        )

    }
}