import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LayoutContent from "../../Partials/LayoutContent";
import GetListBerita from "../Berita/GetListBerita";
import GetListInformasi from "./GetListInformasi";

export default function GetDetail() {
    const uri_param = useParams();
    const slug_id = uri_param.slug;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [detail, setDetail] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}informasi/${slug_id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDetail(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [slug_id])

    let judul, waktu, deskripsi, title;
    if (error) {
        title = 'Error: ' + error.message;
        judul = `<div class="bg-slate-300 rounded-xl h-10 w-full text-gray-500 flex items-center justify-center">${error.message} @JudulInformasi</div>`
        waktu = `<div class="bg-slate-300 rounded-xl h-5 w-1/2 text-gray-500 flex items-center justify-center my-3">${error.message} @TanggalPosting</div>`
        deskripsi = `<div class="bg-slate-300 rounded-xl h-96 w-full text-gray-500 flex items-center justify-center">${error.message} @IsiInformasi</div>`
    } else if (!isLoaded) {
        title = 'Loading...';
        judul = `<div class="bg-slate-300 rounded-xl h-10 w-full text-gray-500 flex items-center justify-center my-3"></div>`
        judul = `<div class="group animate-pulse w-full">
            <div class="bg-slate-300 rounded-xl h-10">&nbsp;</div>
        </div>`
        waktu = `<div class="group animate-pulse w-1/2 my-3">
            <div class="bg-slate-300 rounded-xl h-5">&nbsp;</div>
        </div>`
        deskripsi = `<div class="group animate-pulse w-full">
            <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
        </div>`
    } else {
        title = detail.judul_informasi;
        judul = `<p class="lg:text-3xl md:text-2xl text-xl font-bold text-gray-900">${detail.judul_informasi}</p>`
        waktu = `<p class="my-3 text-sm">Diposting pada <span class="text-blue-900">${detail.tanggal_post}</span> oleh <span class="text-blue-900">${detail.nama_publisher}</span></p>`
        deskripsi = `<p className="text-gray-600 text-justify">${detail.deskripsi}</p>`
    }

    const isiKonten = <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {Parser().parse(judul)}
        {Parser().parse(waktu)}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pt-4 border-t-4">
            <div className="col-span-2 bg-gray-50 p-4 border border-slate-200 rounded-xl shadow">
                {Parser().parse(deskripsi)}
            </div>
            <div className="p-4">
                <div className="mb-8">
                    <GetListInformasi limit="5" />
                </div>
                <GetListBerita limit="5" />
            </div>
        </div>
    </div>

    return (
        <LayoutContent title={title} description={title} konten={isiKonten} />
    )
}