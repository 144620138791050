import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";

export default function Pedoman() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}pedoman`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let pedoman;
    if (error) {
        pedoman = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @Kalender</div>`;
    } else if (!isLoaded) {
        pedoman = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        let listPedoman = '';
        datas.map((list, i) => (
            listPedoman += `<tr key=${i}>
                <td class="p-3 text-gray-800 whitespace-nowrap text-center">
                    ${i + 1}
                </td>
                <td class="p-3 text-gray-800 whitespace-nowrap">
                    ${list.nama_pedoman}
                </td>
                <td class="p-3 text-gray-800 whitespace-nowrap">
                    <a href="${list.url_dokumen}" target="_${i}" rel="noreferrer noopener" class="bg-blue-200 hover:bg-blue-300 text-blue-800 py-2 px-3 rounded-lg text-xs">Download</a>
                </td>
            </tr>`
        ));

        pedoman = `<div class="overflow-auto">
            <table class="min-w-full divide-y divide-gray-200 border">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="p-3 font-bold text-gray-900 text-center">
                            No
                        </th>
                        <th scope="col" class="p-3 font-bold text-left text-gray-900 ">
                            Nama Dokumen
                        </th>
                        <th scope="col" class="p-3 font-bold text-left text-gray-900 ">
                            Download
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                    ${listPedoman}
                </tbody>
            </table>
        </div>`
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="overflow-hidden bg-white shadow rounded-xl">
                <div className="px-4 py-5 sm:px-6">
                    <div className="text-3xl font-bold text-gray-900">Pedoman</div>
                    <div className="text-gray-500">Daftar buku pedoman</div>
                </div>
                <div className="border-t border-gray-200 p-6">
                    {Parser().parse(pedoman)}
                </div>
            </div>
        </div>

    return <LayoutContent title="Pedoman" description={null} konten={isiKonten} />
}