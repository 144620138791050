import { useState, useEffect } from "react";
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Link, useMatch } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MenuProdi({ ...props }) {
    const isMatch = useMatch('/prodi/:sub/:id');
    const active = (isMatch) ? props.class : 'text-gray-700';

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}publisher`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const newData = result.data.filter(object => {
                            return object.kode_publisher !== '043277';
                        });
                        setDatas(newData);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return (
            <div>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className={`inline-flex w-full justify-center items-center text-gray-400 px-1 py-1`}>
                            Program Studi
                            <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                </Menu>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className={`${active} inline-flex w-full justify-center items-center focus:outline-none hover:text-blue-700 px-1 py-1`}>
                            Program Studi
                            <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                </Menu>
            </div>
        )
    } else {
        return (
            <div>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className={`${active} inline-flex w-full justify-center items-center focus:outline-none hover:text-blue-700 px-1 py-1`}>
                            Program Studi
                            <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                {datas.map((item, i) => (
                                    <Menu.Item key={i}>
                                        {({ active }) => (
                                            <Link
                                                to={'/prodi/profil'}
                                                state={{ kode: item.kode_publisher, nama: item.nama_publisher }}
                                                className={classNames(
                                                    active ? ' text-blue-700 bg-blue-100' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                )}
                                            >
                                                {item.nama_publisher}
                                            </Link>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        )

    }
}