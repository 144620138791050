import { Parser } from "html-to-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetKurikulum() {
    const location = useLocation()
    const nama_prodi = location.state.nama;
    const kode_prodi = location.state.kode;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataKuri, setDataKuri] = useState();
    const [dataMKKuri, setDataMKKuri] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API_SIAK}kurikulum_prodi?kode_prodi=${kode_prodi}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDataKuri(result.result)
                        postData(result.result.id_kurikulum);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [kode_prodi])

    const postData = (id_kurikulum) => {
        fetch(`${process.env.REACT_APP_URL_API_SIAK}kurikulum_prodi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_kurikulum: id_kurikulum,
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDataMKKuri(result.result);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            .catch((err) => console.log(err))
    }

    let kurikulum;
    if (error) {
        kurikulum = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @Kurikulum</div>`;
    } else if (!isLoaded) {
        kurikulum = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        let listKurikulum = '';
        dataMKKuri.map((list, i) => (
            listKurikulum += `<tr key="${i}">
            <td class="p-3 text-gray-800 text-center">${i + 1}</td>
            <td class="p-3 text-gray-800">${list.kode_mk.trim()}</td>
            <td class="p-3 text-gray-800">${list.nm_mk.trim()}</td>
            <td class="p-3 text-gray-800 text-center">${list.smt.trim()}</td>
            <td class="p-3 text-gray-800 text-center">${list.sks_mk.trim()}</td>
        </tr>`
        ));

        kurikulum = `<table class="mb-3">
            <tbody>
                <tr>
                    <td class="py-2">Nama Kurikulum</td>
                    <td class="px-6">:</td>
                    <th class="text-left">${dataKuri.nm_kurikulum.trim()}</th>
                </tr>
                <tr>
                    <td class="py-2">Total SKS</td>
                    <td class="px-6">:</td>
                    <th class="text-left">${dataKuri.jml_sks_lulus.trim()}</th>
                </tr>
            </tbody>
        </table>
        <div class="overflow-auto">
            <table class="min-w-full divide-y divide-gray-200 border">
                <thead class="bg-gray-50">
                    <tr>
                        <th class="p-3 font-bold text-left text-gray-900">No</th>
                        <th class="p-3 font-bold text-left text-gray-900">Kode Matkul</th>
                        <th class="p-3 font-bold text-left text-gray-900">Nama Matkul</th>
                        <th class="p-3 font-bold text-left text-gray-900">Semester</th>
                        <th class="p-3 font-bold text-left text-gray-900">SKS</th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">${listKurikulum}</tbody>
            </table>
        </div>`

    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Kurikulum</div>
                        <div className="text-gray-500">Program Studi {nama_prodi}</div>
                    </div>
                    <div className="border-t border-gray-200 p-6">
                        {Parser().parse(kurikulum)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait publisher={{ kode: kode_prodi, nama: nama_prodi }} />
                </div>
            </div>
        </div>


    return <LayoutContent title={`Kurikulum Prodi ${nama_prodi}`} description={null} konten={isiKonten} />
}