import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";

export default function GetKalenderAkademik() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);
    const [tahun, setTahun] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API_SIMAK}smt_aktif?apikey=stikesmu2018!`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setTahun(result.data.id_ta);
                        getKalender(result.data.id_ta);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    const getKalender = (id_ta) => {
        fetch(`${process.env.REACT_APP_URL_API_SIAK}kalender?id_ta=${id_ta}`)
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    if (data.status) {
                        setDatas(data.result);
                    } else {
                        setError(data);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    let kalender;
    if (error) {
        kalender = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @Kalender</div>`;
    } else if (!isLoaded) {
        kalender = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        let listKalender = '';
        datas.map((list, i) => (
            listKalender += `<tr key=${i}>
                <td class="p-3 text-gray-800 whitespace-nowrap text-center">
                    ${i + 1}
                </td>
                <td class="p-3 text-gray-800 whitespace-nowrap">
                    ${list.nm_kegiatan}
                </td>
                <td class="p-3 text-gray-800 whitespace-nowrap">
                    ${list.tgl_mulai_kegiatan_ID}
                </td>
                <td class="p-3 text-gray-800 whitespace-nowrap">
                    ${list.tgl_selesai_kegiatan_ID}
                </td>
            </tr>`
        ));

        kalender = `<div class="overflow-auto">
            <table class="min-w-full divide-y divide-gray-200 border">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="p-3 font-bold text-gray-900 text-center">
                            No
                        </th>
                        <th scope="col" class="p-3 font-bold text-left text-gray-900 ">
                            Nama Kegiatan
                        </th>
                        <th scope="col" class="p-3 font-bold text-left text-gray-900 ">
                            Tanggal Mulai
                        </th>
                        <th scope="col" class="p-3 font-bold text-left text-gray-900 ">
                            Tanggal Selesai
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                    ${listKalender}
                </tbody>
            </table>
        </div>`
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="overflow-hidden bg-white shadow rounded-xl">
                <div className="px-4 py-5 sm:px-6">
                    <div className="text-3xl font-bold text-gray-900">Kalender Akademik</div>
                    <div className="text-gray-500">Daftar kegiatan pada tahun akademik {tahun} / {parseInt(tahun) + 1}</div>
                </div>
                <div className="border-t border-gray-200 p-6">
                    {Parser().parse(kalender)}
                </div>
            </div>
        </div>

    return <LayoutContent title="Kalender Akademik" description={null} konten={isiKonten} />
}