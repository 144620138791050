import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function Akreditasi() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}akreditasi/043277`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDatas(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let akreditasi = '';
    if (error) {
        akreditasi = `<div class="bg-slate-300 rounded-xl h-96 text-gray-500 flex items-center justify-center">${error.message} @ListAkreditasi</div>`
    } else if (!isLoaded) {
        akreditasi = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-96">&nbsp;</div>
                </div>`
    } else {
        // sertifikat akreditasi
        datas.map((list, i) => (
            akreditasi += `
            <div key="${i}" class="max-w p-6 bg-blue-50 rounded-lg border border-blue-100">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold text-gray-900 ">Akreditasi Tahun ${list.tahun_akreditasi}</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 ">Terakreditasi ${list.lembaga_akreditasi} dengan predikat <strong>${list.predikat}</strong><br/>Berlaku sampai ${list.sampai_tanggal}</p>
                ${(list.file_akreditasi)
                ? `<a href="${list.file_akreditasi}" target="_${i}" rel="noopener noreferrer" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">Lihat Sertifikat</a>`
                : `<button class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-gray-500 bg-gray-200  rounded-lg cursor-default">Lihat Sertifikat</button>`
            }
            </div>
            `
        ))
    }

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Akreditasi Institusi</div>
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200 grid gap-5">
                        {Parser().parse(akreditasi)}
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait />
                </div>
            </div>
        </div >

    return <LayoutContent title="Akreditasi" description={null} konten={isiKonten} />
}