import { useLocation } from "react-router-dom";
import LayoutContent from "../../Partials/LayoutContent";
import LinkTerkait from "./LinkTerkait";

export default function GetPanduanKti() {
    const location = useLocation()
    const nama_prodi = location.state.nama
    const kode_prodi = location.state.kode

    const isiKonten =
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="col-span-2 overflow-hidden bg-white shadow rounded-xl">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="text-3xl font-bold text-gray-900">Panduan KTI</div>
                        <div className="text-gray-500">Program Studi {nama_prodi}</div>
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 border-t border-gray-200">
                        <iframe className="w-full h-screen" title="Panduan KTI" src={`https://drive.s3-id-jkt-1.kilatstorage.id/siak/panduan/${kode_prodi}.pdf`}></iframe>
                    </div>
                </div>
                <div className="p-4">
                    <LinkTerkait publisher={{ kode: kode_prodi, nama: nama_prodi }} />
                </div>
            </div>
        </div>


    return <LayoutContent title={`Panduan KTI Prodi ${nama_prodi}`} description={null} konten={isiKonten} />
}