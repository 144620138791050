import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import imgVisiMisi from "./visimisi.png"

export default function GetVisiMisi() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [detail, setDetail] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}publisher/043277`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        setDetail(result.data);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    let visi, misi;
    if (error) {
        visi = `<div class="bg-slate-300 rounded-xl h-28 text-gray-500 flex items-center justify-center">${error.message} @Visi</div>`
        misi = `<div class="bg-slate-300 rounded-xl h-60 text-gray-500 flex items-center justify-center">${error.message} @Misi</div>`
    } else if (!isLoaded) {
        visi = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-28">&nbsp;</div>
                </div>`
        misi = `<div class="group animate-pulse">
                    <div class="bg-slate-300 rounded-xl h-60">&nbsp;</div>
                </div>`
    } else {
        visi = `<div class="text-xl md:text-3xl font-bold border-b-2 border-yellow-600 py-3 mb-3">Visi</div>
                <p class="text-sm md:text-base lg:text-lg">${detail.visi}</p>`
        misi = `<div class="text-xl md:text-3xl font-bold border-b-2 border-yellow-600 py-3 mb-3">Misi</div>
                <p class="text-sm md:text-base lg:text-lg">${detail.misi}</p>`
    }

    return (
        <div className="bg-white w-full py-8 lg:py-12 border-b-2 border-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative md:py-10">
                    <div className="text-yellow-900 bg-yellow-400 md:bg-transparent rounded-xl p-5 md:p-0">
                        <img src={imgVisiMisi} className="w-32 md:w-44 lg:w-52 md:absolute md:inset-x-16 md:bottom-0 m-auto md:m-0" alt="VisiMisi" loading="lazy" />
                        <div className="md:p-5 bg-transparent rounded-xl grid grid-cols-1 md:grid-cols-3">
                            <div></div>
                            <div className="col-span-2">
                                {Parser().parse(visi)}
                            </div>
                        </div>
                        <div className="md:p-5 bg-yellow-400 rounded-xl grid grid-cols-1 md:grid-cols-3">
                            <div></div>
                            <div className="col-span-2">
                                {Parser().parse(misi)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}