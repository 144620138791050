import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Link, useMatch } from 'react-router-dom'

const menu_list = [
    { name: 'Visi & Misi', href: '/profil/visimisi' },
    { name: 'Akreditasi', href: '/profil/akreditasi' },
    { name: 'Dosen dan Tenaga Kependidikan', href: '/profil/dosentendik' },
    { name: 'Fasilitas', href: '/profil/fasilitas' },
    { name: 'Sejarah', href: '/profil/sejarah' },
    { name: 'Makna Logo', href: '/profil/makna_logo' },
    { name: 'Lagu-lagu', href: '/profil/lagu' },
    { name: 'Kalender Akademik', href: `/kalender` },
]

export default function MenuProfil({ ...props }) {
    const isMatch = useMatch('/profil/:id');
    const active = (isMatch) ? props.class : 'text-gray-700';

    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className={`${active} inline-flex w-full justify-center items-center focus:outline-none hover:text-blue-700 px-1 py-1`}>
                        Profil Kampus
                        <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute left-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            {menu_list.map((item, i) => (
                                <Link key={i} to={item.href} className="text-gray-700 block px-4 py-2 text-sm hover:bg-blue-100 hover:text-blue-700">{item.name}</Link >
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}