import { Parser } from "html-to-react";
import { useState, useEffect } from "react";
import noImage from "./no-image-berita.jpg";

export default function GetBerita({ ...props }) {
    const limit = props.limit;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}berita`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const first10 = result.data.slice(0, limit);
                        setDatas(first10);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [limit])



    let berita = '';
    if (error) {
        berita = `<div class="bg-slate-300 rounded-xl h-28 w-screen text-gray-500 flex items-center justify-center">${error.message} @Berita</div>`
    } else if (!isLoaded) {
        berita = `<div class="group animate-pulse w-screen">
                    <div class="bg-slate-300 rounded-xl h-28">&nbsp;</div>
                </div>`
    } else {
        // get tag image <img> untuk dijadikan cover
        const getImgSrc = (imgStr) => {
            const div = document.createElement('div')
            div.innerHTML = imgStr
            const img = div.querySelector('img')
            if (img) {
                return img.src;
            } else {
                return noImage;
            }
        }

        datas.map((list, i) => (
            berita += `<div class="snap-start shrink-0 w-60 flex items-stretch">
                <div key='${i}' class="text-blue-900 hover:cursor-pointer bg-white rounded-xl shadow-md">
                    <a href='/berita/${list.slug_berita}' class="static">
                    <img src="${getImgSrc(list.deskripsi)}" class="object-cover w-full h-32 rounded-t-xl" alt="${list.judul_sub}" loading="lazy"/>
                    <p class="text-xs my-2 absolute bg-blue-900 py-2 px-3 text-white rounded-tr-xl -mt-8 w-44">${list.tanggal_post}</p>
                    <p class="font-bold p-3 uppercase text-sm md:text-md">${list.judul_sub}</p>
                    </a>
                </div>
            </div>`
        ))
    }

    return (
        <>
            <div className="block md:hidden font-bold text-xl md:text-3xl text-center text-gray-900">Berita Terkini</div>
            <div className="relative md:py-6 px-0 md:px-3 lg:pr-0 md:pl-60">
                <div className="hidden h-full w-full md:w-80 bg-gradient-to-r from-blue-700 to-blue-500 p-10 absolute inset-y-0 left-0 rounded-xl md:flex items-center">
                    <div className="text-yellow-900 rounded-lg bg-yellow-400 text-3xl font-bold -skew-y-12 shadow-md shadow-blue-700 p-3">BERITA<br />TERKINI</div>
                </div>
                <div className="relative w-full flex space-x-4 snap-x snap-mandatory overflow-x-auto py-3 md:py-3">
                    {Parser().parse(berita)}
                </div>
            </div>
        </>
    )
}