import { Parser } from "html-to-react";
import { useState, useEffect } from "react";

export default function GetListBerita({ ...props }) {
    const limit = props.limit;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}berita`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    if (result.status) {
                        const first10 = result.data.slice(0, limit);
                        setDatas(first10);
                    } else {
                        setError(result);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [limit])


    let recent = '';
    if (error) {
        recent = `<div class="bg-slate-300 rounded-xl h-20 w-full text-gray-500 flex items-center justify-center">${error.message} @BeritaLainnya</div>`
    } else if (!isLoaded) {
        recent = `<div class="group animate-pulse w-full">
            <div class="bg-slate-300 rounded-xl h-20">&nbsp;</div>
        </div>`
    } else {
        datas.map((list, i) => (
            recent += `<div key=${i} class="py-2 border-b hover:text-blue-900">
                <a href=${'/berita/' + list.slug_berita} class="grid grid-cols-1">
                    ${list.judul_sub}
                    <small class="text-gray-500">${list.tanggal_post}</small>
                </a>
            </div>`
        ))
    }

    return (
        <div className="overflow-hidden">
            <h3 className="text-xl font-bold leading-6 text-gray900 mb-5">Berita Terkini</h3>
            <div className="border-t border-gray-200">
                {Parser().parse(recent)}
            </div>
        </div>
    )
}